.contact-form-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 70px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out;
}

.contact-form-container:hover {
  transform: scale(1.05);
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-family: "Courier New", Courier, monospace;
  margin-top: 10px;
  color: #ffffff;
  text-align: left;
}

input,
textarea {
  margin-bottom: 10px;
  font-size: 15px;
  padding: 18px;
  border: 1px solid #ddd;
  border-radius: 15px;
  transition: border-color 0.3s ease-in-out;
}

input:focus,
textarea:focus {
  border-color: #007bff;
}

button {
  background-color: #2207ad;
  color: #fff;
  margin-top: 5%;
  padding: 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #ffffff;
  color: #000000;
}
