.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}
.dark {
  background: black;
  color: white;
}

.light {
  background: white;
  color: black;
}

.navbar {
  background: black;
  color: white;
  /* Other navbar styles */
}

.dark .navbar {
  background: black;
  color: white;
}

.light .navbar {
  background-color: #f0f0f0;
  color: black;
}

.App-link {
  color: #61dafb;
}
.welcome-text {
  font-family: "Courier New", Courier, monospace;
}
.projects-section {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 3s;
  font-family: "Courier New", Courier, monospace;
  margin-top: 10%;
}

.project-cards {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.project-card {
  width: calc(50.33% - 20px);
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
}

.card-content {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.card-content img {
  width: 100%;
  height: 190px;
  object-fit: cover;
}

.card-content h3 {
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
}

.description-section {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  font-family: "Courier New", Courier, monospace;
}
/* Media Query for Mobile */
@media screen and (max-width: 768px) {
  .project-cards {
    padding: 0;
  }

  .project-card {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.description-section-two {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 2s;
  font-family: "Courier New", Courier, monospace;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
